import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ClienteService {
  async getClientesAll() {
    const clientes = await fetchWrapper.get(`${ruta}/cliente`);
    return clientes;
  }

  async sendClienteNuevo(cliente) {
    const nuevoCliente = await fetchWrapper.post(`${ruta}/cliente`, cliente);
    return nuevoCliente;
  }
  async deleteCliente(cliente) {
    const clienteEliminado = await fetchWrapper.delete(
      `${ruta}/cliente/` + cliente.id
    );
    return clienteEliminado;
  }
  async updatedCliente(cliente) {
    const clienteActualizado = await fetchWrapper.put(
      `${ruta}/cliente/` + cliente.id,
      cliente
    );
    return clienteActualizado;
  }
  async showCliente(cliente) {
    const clienteShow = await fetchWrapper.get(`${ruta}/cliente/` + cliente);
    return clienteShow;
  }

  async filtrarClientes(datos){
    return await fetchWrapper.post(`${ruta}/filtros_cliente`, datos);
  }

  async showClienteVentas(cliente){
    return await fetchWrapper.get(`${ruta}/cliente_ventas/` + cliente);
  }

  async generarPDF(datos){
    return await fetchWrapper.postPdf(
			`${ruta}/reporte/cliente/imprimir`,
			datos
		);
  }

  async exportarClientes(datos){
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/clientes-export`,
      datos
    );
    return exportado;
  }
  async buscarXNombre(datos) {
    return await fetchWrapper.post(`${ruta}/cliente/buscar`, datos);
  }

  async buscarClientePorNit(ci_nit) {
    return await fetchWrapper.post(`${ruta}/cliente/buscar/nit`, ci_nit);
  }

  async cargarPagoClientes(datos) {
    return await fetchWrapper.post(`${ruta}/pago_cliente`, datos);
  }
}