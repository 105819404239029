<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <router-link to="/reportes/clientes"
        ><span class="text-500 line-height-3"
          >Reporte de Clientes</span
        ></router-link
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8 border-2">
    <div
      class="
        flex
        align-items-center
        flex-column
        lg:justify-content-center lg:flex-row
        mb-4
      "
    >
      <h4><strong>DETALLE DE VENTAS DE CLIENTES</strong></h4>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtclienteventas"
        :value="ventas"
        key="id"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 30, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Ventas Clientes"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
      >
        <Column field="id_venta" header="ID VENTA" :sortable="true" />
        <Column field="sucursal" header="SUCURSAL" />
        <Column field="usuario" header="USUARIO VENTA" />
        <Column field="cliente" header="CLIENTE" />
        <Column field="nit" header="NIT/CI" />
        <Column field="fecha_venta" header="FECHA VENTA" :sortable="true" />
        <Column field="producto" header="PRODUCTO" />
        <Column field="cantidad" header="CANTIDAD" :sortable="true" />
        <Column field="precio" header="PRECIO UNITARIO" :sortable="true" />
        <Column field="descuento" header="DESCUENTO" :sortable="true" />
        <Column field="total_cancelar" header="TOTAL CANCELAR" />
      </DataTable>
    </div>
    <div
      class="
        mt-4
        flex
        align-items-end
        flex-column
        lg:justify-content-end lg:flex-row
      "
    >
      <Button @click="goBack">
        <i class="pi pi-arrow-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>
    </div>
  </div>
</template>
<script>
import ClienteService from "@/service/ClienteService";
export default {
  data() {
    return {
      idCliente: this.$route.params.id,
      ventas: [],
    };
  },
  clienteService: null,
  created() {
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.cargarVentas();
  },
  methods: {
    cargarVentas() {
      this.clienteService.showClienteVentas(this.idCliente).then((data) => {
        this.ventas = data.ventas;
      });
    },
    goBack() {
      this.$router.push("/reportes/clientes");
    },
  },
};
</script>